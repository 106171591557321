<template>
  <AdminPageFrame class="w-main-container">
  <PageTitle>
    {{ $route.params.id ? trans('PAGE_TITLE:PartnerEdit') : trans('PAGE_TITLE:PartnerAdd') }}
  </PageTitle>
  <t-card class="w-full">
    <FieldWrapper class="mb-3" :label="trans('LABEL:Name')">
      <TextField v-model="partner.name">

      </TextField>
    </FieldWrapper>
    <FieldWrapper class="mb-3" :label="trans('LABEL:Email')">
      <TextField rules="required|email" v-model="partner.email">

      </TextField>
    </FieldWrapper>
    <FieldWrapper class="mb-3" :label="trans('LABEL:NIP')">
      <TextField rules="required|max:10" v-model="partner.nip">

      </TextField>
    </FieldWrapper>
    <FieldWrapper class="mb-3" :label="trans('LABEL:CommissionPercentage')">
      <TextField :field-type="'number'" rules="required" v-model="partner.commissionPercentage">

      </TextField>
    </FieldWrapper>
    <div class="flex flex-row justify-end">
      <Button :text="trans('BUTTON:Save')" :loading="partnersSaving" @clicked="storePartnerAction">
      </Button>
    </div>
  </t-card>
  </AdminPageFrame>
</template>

<script>
import AdminPageFrame from "@/views/content/admin/components/AdminPageFrame";
import Button from "@/utils/crud/components/Button";
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper";
import PageTitle from "@/layouts/components/pages/PageTitle";
import TextField from "@/utils/crud/components/field-types/Text";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PartnerShow",
  components: {
    AdminPageFrame,
    Button,
    FieldWrapper,
    PageTitle,
    TextField,
  },
  data() {
    return {
      partner: {},

    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(val) {
        if(val) {
          this.fetchShowPartnerRequest(val);
        }
      }
    },
    partnerLoaded(val) {
      if(val) {
        this.partner = structuredClone(this.partnerData)
      }
    }
  },
  computed: {
    ...mapGetters('partners', ['partnersSaving', 'partnerLoaded', 'partnerData'])
  },
  methods: {
    ...mapActions('partners', ['storePartnerData', 'fetchShowPartnerRequest', 'updatePartnerData']),
    storePartnerAction() {
      if(this.$route.params.id) {
        this.updatePartnerData(this.partner);
        return;
      }
      this.storePartnerData(this.partner);
    }
  }
}
</script>
